<template>
  <component
    :is="href ? 'a' : to ? NuxtLink : 'button'"
    v-bind="$attrs"
    :href="href || undefined"
    :to="to"
    class="no-tap-highlight"
    :class="isType === 'custom' ? $attrs.class : classList"
    @click="onClick"
  >
    <span
      :class="[isType !== 'custom' && classListSpan, icon && isType !== 'custom' && '!pr-[60px]']"
    >
      {{ label }}
      <slot />
      <Icon
        v-if="icon"
        :name="typeof icon === 'string' ? icon : icon.name"
        :size="typeof icon === 'string' ? 'sm' : icon.size"
        :color="typeof icon === 'string' ? 'white' : icon.color"
        :class="[
          ['primary', 'secondary', 'text'].includes(isType)
            ? 'absolute right-5 top-1/2 -translate-y-1/2'
            : isType === 'circle' || isType === 'circle-secondary'
            ? 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
            : null,
          typeof icon === 'object' && icon.class
        ]"
      />
    </span>
  </component>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')

type ButtonType = 'primary' | 'secondary' | 'text' | 'circle' | 'circle-secondary' | 'custom'

interface IconProps {
  color?: string
  name: string
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'circle'
  class?: string
}

interface Props {
  disabled?: boolean
  href?: string
  icon?: string | IconProps
  isType?: ButtonType
  label?: string
  loading?: boolean
  to?: string | object | undefined | null
  prevent?: boolean
  size?: 'default' | 'md' | 'sm'
  spanClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  isType: 'primary',
  loading: false,
  prevent: false,
  size: 'default'
})

const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()

const classList = computed(() => {
  return [
    $style.button,
    {
      [$style.small]: props.size === 'sm',
      [$style.primary]: props.isType === 'primary',
      [$style.secondary]: props.isType === 'secondary',
      [$style.text]: props.isType === 'text',
      [$style.circle]: props.isType === 'circle',
      [$style['circle-secondary']]: props.isType === 'circle-secondary',
      // State
      'pointer-events-none pointer-default': props.disabled || props.loading
    }
  ]
})

const classListSpan = computed(() => {
  return [
    $style.span,
    {
      // Box Model
      [$style['span-small']]: props.size === 'sm',

      [$style['span-primary']]: props.isType === 'primary',
      [$style['span-secondary']]: props.isType === 'secondary',
      [$style['span-text']]: props.isType === 'text',
      [$style['span-circle']]: props.isType === 'circle',
      [$style['span-circle-secondary']]: props.isType === 'circle-secondary',

      // State
      [$style.disabled]: props.disabled,
      [$style.loading]: props.loading
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled || props.loading || props.prevent) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}
</script>

<style lang="postcss" module>
@keyframes translateRight {
  49% {
    transform: translate(100%);
  }

  50% {
    transform: translate(-100%);
  }
}

.button {
  --size: 56px;

  @apply relative inline-flex h-[var(--size)] w-fit cursor-pointer justify-center whitespace-nowrap border-2 font-semibold uppercase leading-none text-white no-underline transition-colors duration-300;

  &:hover {
    use {
      animation: translateRight 360ms var(--ease-out-quart) forwards;
    }
  }
}

.primary {
  @apply min-w-[var(--size)]  border-red-60 bg-primary;

  &:hover {
    @apply bg-black text-white border-white;
  }
}

.span {
  @apply relative top-0 flex h-full flex-col justify-center overflow-hidden px-6 text-center;
}

/* Secondary */

.secondary {
  @apply min-w-[var(--size)] rounded-full bg-transparent bg-white text-black shadow-lg;

  &:hover {
    @apply bg-black text-white;

    &:hover {
      use {
        @apply fill-white;
      }
    }
  }

  &:active {
    @apply bg-black text-white;
  }
}

.text {
  @apply border-none bg-none text-primary;
}

.circle {
  @apply h-10 w-10 rounded-full border-red-50 bg-red-50;
}

.circle-secondary {
  @apply h-10 w-10 rounded-full;
}

/* Small */

.small {
  --size: 40px;
}

.span-small {
  @apply px-4;
}

/* State */
.disabled {
  color: theme('colors.disabled');
  border-color: theme('colors.disabled');
}

.loading {
  color: transparent;
}
</style>
